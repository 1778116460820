// 分割线
// =============================================================================

// 添加边框作为分割线，亮色的分割线，用于深色背景
.mdui-divider-top-light() {
  border-top: 1px solid @color-white-divider;
}
.mdui-divider-bottom-light() {
  border-bottom: 1px solid @color-white-divider;
}
.mdui-divider-left-light() {
  border-left: 1px solid @color-white-divider;
}
.mdui-divider-right-light() {
  border-right: 1px solid @color-white-divider;
}

// 添加边框作为分割线，深色的分割线，用于浅色��景
.mdui-divider-top-dark() {
  border-top: 1px solid @color-black-divider;
}
.mdui-divider-bottom-dark() {
  border-bottom: 1px solid @color-black-divider;
}
.mdui-divider-left-dark() {
  border-left: 1px solid @color-black-divider;
}
.mdui-divider-right-dark() {
  border-right: 1px solid @color-black-divider;
}
